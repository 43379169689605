import React, { useState, useContext } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import { OrderContext } from '../../context/OrderContext';

const EmailConfirmationModal = ({ isOpen, onClose }) => {
  const [code, setCode] = useState('');
  const { setCurrentStep } = useContext(OrderContext);

  const handleConfirm = () => {
    // Validation du code
    setCurrentStep(5);
    onClose();
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflowY: 'auto',
    width: 500,
  };

 
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <TextField
          label="Code de Confirmation"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Button onClick={handleConfirm}>Valider</Button>
      </Box>
    </Modal>
  );
};

export default EmailConfirmationModal;
