import React from 'react';
import { OrderProvider } from './context/OrderContext';
import ComingSoonPage from './Components/ComingSoonPage';

function App() {
  return (
    <OrderProvider>
      <ComingSoonPage />
    </OrderProvider>
  );
}

export default App;
