import React, { useState, useContext, useEffect } from 'react';
import { Modal, Box, Grid, TextField, Button, Checkbox, FormControlLabel, Typography, styled } from '@mui/material';
import { Formik, Form, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import FileUploadComponent from '../FileUploadComponent';
import ConfirmationModal from '../ConfirmationModal';
import { OrderContext } from '../../context/OrderContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const validationSchema = Yup.object().shape({
  nom: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Le nom ne doit contenir que des lettres et des espaces")
    .required('Champ obligatoire'),
  prenom: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Le prénom ne doit contenir que des lettres et des espaces")
    .required('Champ obligatoire'),
  email: Yup.string().email('Email invalide').required('Champ obligatoire'),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Les emails doivent correspondre')
    .required('Champ obligatoire'),
    telephone: Yup.string()
    .matches(
      /^(0[1-9][0-9]{8}|(\+33|0033)[1-9][0-9]{8})$/, 
      "Le numéro de téléphone doit être au format 0611223344, +33611223344 ou 0033611223344"
    )
    .required('Champ obligatoire'),
  adresse: Yup.string().required('Champ obligatoire'),
  complementAdresse: Yup.string(),
  codePostal: Yup.string()
    .matches(/^[0-9]{5}$/, "Le code postal doit contenir 5 chiffres")
    .required('Champ obligatoire'),
  ville: Yup.string().required('Champ obligatoire'),
  document: Yup.mixed().required("Un document est requis"),
  acceptTerms: Yup.bool().oneOf([true], 'Vous devez accepter les termes'),
});

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#f7f7f7',
    borderRadius: 10,
    border: 'none',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      border: '2px solid #004AC1', // Bordure bleue lors du focus
    },
    '&.Mui-error fieldset': {
      border: '2px solid red', // Bordure rouge en cas d'erreur
    },
  },
  '& .MuiInputBase-input': {
    color: 'black', // Couleur du texte
  },
}));

const continueButtonStyle = {
  mt: 2,
  backgroundColor: '#11775A', // Votre couleur personnalisée
  color: 'white',
  width:'250px',
  padding: '10px 20px', // Plus de padding
  borderRadius: '15px', // Plus de border radius
  '&:hover': {
    backgroundColor: 'lighten(#11775A, 0.1)', // Légèrement plus clair au survol
  },
};

const OrderFormModal = ({ isOpen, onClose }) => {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { orderDetails, setOrderDetails, setCurrentStep } = useContext(OrderContext);

  const initialValues = {
    nom: orderDetails.nom || '',
    prenom: orderDetails.prenom || '',
    email: orderDetails.email || '',
    confirmEmail: orderDetails.confirmEmail || '',
    telephone: orderDetails.telephone || '',
    adresse: orderDetails.adresse || '',
    complementAdresse: orderDetails.complementAdresse || '',
    codePostal: orderDetails.codePostal || '',
    ville: orderDetails.ville || '',
    acceptTerms: orderDetails.acceptTerms || false,
    uploadedFile: orderDetails.uploadedFile || null,
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '70vh',
    overflowY: 'auto',
    width: '60%',
    textAlign: 'left' // Alignement du texte à gauche
  };

  const handleModalClose = () => {
    if (isFormDirty) {
      setShowConfirmationModal(true);
      return;
    }
    onClose();
  };

  const confirmClose = () => {
    setShowConfirmationModal(false);
    setIsFormDirty(false);
    onClose();
  };

  const cancelClose = () => {
    setShowConfirmationModal(false);
  };

  const FormInitializer = () => {
    const { setFieldValue } = useFormikContext();
  
    useEffect(() => {
      Object.keys(orderDetails).forEach(key => {
        setFieldValue(key, orderDetails[key], false);
      });
    }, [orderDetails, setFieldValue]);
  
    return null;
  };
  
  

  const handleFormChange = (handleChange) => (e) => {
    setIsFormDirty(true);
    handleChange(e);
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleModalClose} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={modalStyle}>
          <Typography variant="h6">Vos Informations :</Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setOrderDetails(prevDetails => ({ ...prevDetails, ...values }));
              setCurrentStep(2);
              onClose();
            }}
          >
            {({ errors, touched, setFieldValue, handleChange, isValid, handleSubmit, dirty }) => (
              <>
                <FormInitializer />
                <Form onChange={handleFormChange(handleChange)} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    {/* Nom et Prénom */}
                    <Grid item xs={12} sm={6}>
                      <Field as={CustomTextField} fullWidth name="nom" label="* Nom" error={touched.nom && !!errors.nom} helperText={touched.nom && errors.nom} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field as={CustomTextField} fullWidth name="prenom" label="* Prénom" error={touched.prenom && !!errors.prenom} helperText={touched.prenom && errors.prenom} />
                    </Grid>

                    {/* Email et Confirmation Email */}
                    <Grid item xs={12} sm={6}>
                      <Field as={CustomTextField} fullWidth name="email" label="* Email" error={touched.email && !!errors.email} helperText={touched.email && errors.email} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field as={CustomTextField} fullWidth name="confirmEmail" label="* Confirmer Email" error={touched.confirmEmail && !!errors.confirmEmail} helperText={touched.confirmEmail && errors.confirmEmail} />
                    </Grid>

                    {/* Adresse et Complément d'Adresse */}
                    <Grid item xs={12}>
                      <Field as={CustomTextField} fullWidth name="adresse" label="* Adresse" error={touched.adresse && !!errors.adresse} helperText={touched.adresse && errors.adresse} />
                    </Grid>
                    <Grid item xs={12}>
                      <Field as={CustomTextField} fullWidth name="complementAdresse" label="Complément d'adresse" />
                    </Grid>

                    {/* Code Postal et Ville */}
                    <Grid item xs={12} sm={6}>
                      <Field as={CustomTextField} fullWidth name="codePostal" label="* Code Postal" error={touched.codePostal && !!errors.codePostal} helperText={touched.codePostal && errors.codePostal} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field as={CustomTextField} fullWidth name="ville" label="* Ville" error={touched.ville && !!errors.ville} helperText={touched.ville && errors.ville} />
                    </Grid>

                    {/* Téléphone */}
                    <Grid item xs={12}>
                      <Field as={CustomTextField} fullWidth name="telephone" label="* Téléphone" error={touched.telephone && !!errors.telephone} helperText={touched.telephone && errors.telephone} />
                    </Grid>

                    {/* Document */}
                    <Grid item xs={12}>
                    <Field name="document">
                      {({ form }) => (
                              <FileUploadComponent setFieldValue={setFieldValue} setOrderDetails={setOrderDetails} />
                      )}
                    </Field>

                        {touched.document && errors.document && <Typography color="error">{errors.document}</Typography>}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Field as={Checkbox} name="acceptTerms" color="primary" />}
                          label="J'accepte les termes et conditions"
                        />
                        {touched.acceptTerms && errors.acceptTerms && <Typography color="error">{errors.acceptTerms}</Typography>}
                      </Grid>
                      <Grid item xs={12}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button 
                        sx={continueButtonStyle}
                        type="submit" 
                        color="primary" 
                        variant="contained" 
                        fullWidth disabled={!isValid || !dirty}
                        startIcon={<ArrowForwardIcon />} // Icône ajoutée au bouton
                        >
                          Valider
                        </Button>
                        </Box>

                      </Grid>
                  </Grid>
                </Form>
                </>
            )}
          </Formik>
        </Box>
      </Modal>
      <ConfirmationModal open={showConfirmationModal} onConfirm={confirmClose} onCancel={cancelClose} />
    </div>
  );
};

export default OrderFormModal;

 