import React, { useContext } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { OrderContext } from '../../context/OrderContext';

const FinalConfirmationModal = ({ isOpen, onClose }) => {
  const { setCurrentStep } = useContext(OrderContext);

  const handleClose = () => {
    setCurrentStep(0); // Réinitialiser le processus ou rediriger vers une autre page
    onClose();
  };
  
  const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
        maxHeight: '90vh',
        overflowY: 'auto',
        width: 500,
      };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6">Confirmation</Typography>
        <Typography>Votre commande a été confirmée.</Typography>
        <Button onClick={onClose}>Fermer</Button>
      </Box>
    </Modal>
  );
};

export default FinalConfirmationModal;
