import React, { useState, useEffect } from 'react';

const styles = {
  countdown: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
  },
  countdownNumber: {
    fontSize: '4rem',
    color: '#9EA3A6',
    margin: '0 10px',
  },
  countdownText: {
    display: 'block',
    fontSize: '1rem',
    color: '#9EA3A6',
  },
};

const calculateTimeLeft = (endDate) => {
  const difference = +new Date(endDate) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      jours: Math.floor(difference / (1000 * 60 * 60 * 24)),
      heures: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      secondes: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const Countdown = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);
    return () => clearTimeout(timer);
  });

  const formatTime = (number) => number.toString().padStart(2, '0');

  return (
    <div style={styles.countdown}>
      <div style={styles.countdownNumber}>{formatTime(timeLeft.jours)}<span style={styles.countdownText}>Jours</span></div>
      <div style={styles.countdownNumber}>{formatTime(timeLeft.heures)}<span style={styles.countdownText}>Heures</span></div>
      <div style={styles.countdownNumber}>{formatTime(timeLeft.minutes)}<span style={styles.countdownText}>Minutes</span></div>
      <div style={styles.countdownNumber}>{formatTime(timeLeft.secondes)}<span style={styles.countdownText}>Secondes</span></div>
    </div>
  );
};

export default Countdown;
