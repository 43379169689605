import React, { useState, useContext, useEffect} from 'react';
import { Paper, Typography, IconButton, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { PDFDocument } from 'pdf-lib';
import { OrderContext } from '../context/OrderContext'; // Ajustez le chemin si nécessaire

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 Mo

const styles = {
  paper: {
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: '#e3f2fd',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  fileDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  linearProgress: {
    backgroundColor: '#00FF00', // Vert fluo pour la barre de progression
  },
};

const FileUploadComponent = ({ setFieldValue }) => {
  const { orderDetails, setOrderDetails } = useContext(OrderContext);
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    // Initialiser files avec orderDetails.uploadedFile
    if (orderDetails.uploadedFile) {
      setFiles(orderDetails.uploadedFile);
    }
  }, [orderDetails.uploadedFile]);

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    let totalSize = files.reduce((acc, file) => acc + file.size, 0);

    for (const file of selectedFiles) {
      totalSize += file.size;
      if (totalSize > MAX_FILE_SIZE) {
        alert("La taille totale des fichiers dépasse 20 Mo.");
        return;
      }
    }

    for (const file of selectedFiles) {
      if (file.type === 'application/pdf') {
        try {
          const arrayBuffer = await file.arrayBuffer();
          const pdfDoc = await PDFDocument.load(arrayBuffer);
          const numPages = pdfDoc.getPageCount();
          file.numPages = numPages; // Ajoute le nombre de pages à l'objet fichier
        } catch (error) {
          console.error("Erreur lors de la lecture du fichier PDF:", error);
        }
      }
    }

    const newFiles = [...files, ...selectedFiles];
    setFiles(newFiles);
  
    setOrderDetails(prevDetails => ({
      ...prevDetails,
      uploadedFile: newFiles // Mettre à jour uniquement le champ uploadedFile
    }));
  
    setFieldValue('document', newFiles);
    startUploadProgress();
  };

  
  const startUploadProgress = () => {
    setUploadProgress(10);
    setTimeout(() => setUploadProgress(100), 1000); // Simuler une progression rapide
  };

  const handleDeleteFile = (fileName) => {
    const updatedFiles = files.filter(file => file.name !== fileName);
    setFiles(updatedFiles);
    setFieldValue('document', updatedFiles);
    setOrderDetails(prevDetails => ({ ...prevDetails, uploadedFile: updatedFiles }));
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} bytes`;
    else if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`;
    else return `${(size / 1048576).toFixed(1)} MB`;
  };

  return (
    <div>
      <Paper variant="outlined" component="label" style={styles.paper}>
        <input
          type="file"
          hidden
          onChange={handleFileChange}
          accept=".pdf,.doc,.docx"
          multiple
        />
        <CloudUploadIcon style={{ fontSize: '2rem' }} />
        <Typography>Choisir des fichiers ou glisser-déposer ici</Typography>
      </Paper>
      {uploadProgress > 0 && uploadProgress < 100 && (
        <LinearProgress variant="determinate" value={uploadProgress} style={styles.linearProgress} />
      )}
      <Typography variant="caption" display="block" gutterBottom color={'green'}>
        20 Mo maximum
      </Typography>
      {files.map((file, index) => (
        <div key={index} style={styles.fileDetails}>
          <Typography variant="body2">
            {file.name} ({formatFileSize(file.size)})
            {file.numPages && ` - ${file.numPages} pages`}
          </Typography>
          <IconButton onClick={() => handleDeleteFile(file.name)}>
            <DeleteIcon color="secondary" />
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default FileUploadComponent;
