import React from 'react';
import image from '../assets/3.png'; // Ajustez le chemin
import Countdown from './Countdown';
import { keyframes } from '@emotion/react';


const blink = keyframes`
0%, 100% { opacity: 0; }
50% { opacity: 1; }
`;


const dotStyle = {
    animation: `${blink} 1.4s infinite`,
  };
  
  const secondDotStyle = {
    ...dotStyle,
    animationDelay: '0.2s',
  };
  
  const thirdDotStyle = {
    ...dotStyle,
    animationDelay: '0.4s',
  };


const styles = {
  main: {
    fontFamily: 'Nunito, Arial, sans-serif',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: '20px',
  },
  image: {
    maxWidth: '15%',
    height: 'auto',
    borderRadius: '15px',
    marginTop: '0',
    marginBottom: '10px',
  },
  instructionText: {
    marginTop: '10px',
    marginBottom: '20px',
    color: '#3D474D',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    borderWith : '2', 
    borderStyle : 'solid',
    borderColor : '#F2F2F2',
    borderRadius : '15px',
    padding : '15px',
    backgroundColor: '#F5F3F7'

  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  button: {
    marginBottom: '10px',
    marginTop: '0.5rem',
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    borderRadius: '5px',
    cursor: 'pointer',
    background: '#FD0A7A',
    border: 'none',
    color: 'white',
    width: '120px',
  }, 
  constructionText: {
    top:0,
    fontWeight: 'bold', // Mettre le texte en gras
    color: '#3D474D', // Définir la couleur du texte
  },
};
 
const MainContent = ({ openModal, openFormModal }) => {
  return (
    <main style={styles.main}>
      {/* Texte SEO-friendly avec des mots-clés spécifiques */}
      <p style={styles.constructionText}>
        ⏳ Le Site de PrintmyDoc - Impression & Reliure est en cours de construction et sera bientôt disponible
        <span style={styles.dotStyle}>.</span>
        <span style={styles.secondDotStyle}>.</span>
        <span style={styles.thirdDotStyle}>.</span>
      </p>
      <Countdown targetDate="2024-03-01" />
      {/* Mise à jour de l'attribut alt pour l'image */}
      <img src={image} alt="Impression et reliure de documents à Amiens, PrintmyDoc logo" style={styles.image} />
      {/* Amélioration du texte pour inclure des mots-clés et être plus explicatif */}
      <p style={styles.instructionText}>
      ❗👉 Mais vous pouvez dès à présent passer une commande d'impression 🛒 en cliquant sur le bouton ci-dessous 👇
      </p>
      <div style={styles.buttonContainer}>
        <button style={styles.button} onClick={openModal}>
          Contacter
        </button>
        <button style={styles.button} onClick={openFormModal}>
          Commander
        </button>
      </div>
    </main>
  );
};

 
export default MainContent;
