import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const styles = {
    container: {
        fontFamily: 'Nunito, Arial, sans-serif',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: '20px',
        width: '100%',
    }
};

const LoadingSkeleton = () => {
    return (
        <div style={styles.container}>
            <Skeleton variant="text" width="100%" height={118} />
            <Skeleton variant="text" width="100%" height={118} />
            <Skeleton variant="text" width="100%" height={118} />
            <Skeleton variant="rect" width="100%" height={118} />
            {/* Vous pouvez ajouter plus de lignes de Skeleton si nécessaire */}
        </div>
    );
};

export default LoadingSkeleton;
