import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const confirmationModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflowY: 'auto',
    width: 500,
};

const ConfirmationModal = ({ open, onConfirm, onCancel }) => {
  return (
    <Modal open={open} onClose={onCancel} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={confirmationModalStyle}>
        <Typography variant="h6" gutterBottom>Confirmer la fermeture</Typography>
        <Typography>Des modifications sont en cours. Êtes-vous sûr de vouloir quitter ?</Typography>
        <Box mt={4} display="flex" justifyContent="center" gap={2}>
          <Button variant="contained" color="primary" onClick={onConfirm}>Oui</Button>
          <Button variant="outlined" onClick={onCancel}>Annuler</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
