import React, { createContext, useState } from 'react';

export const OrderContext = createContext({
    currentStep: null,
    setCurrentStep: () => {},
    orderDetails: {},
    setOrderDetails: () => {}
  });
  
export const OrderProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [orderDetails, setOrderDetails] = useState({
    acceptTerms: false,
    uploadedFile: null,
  });   

  return (
    <OrderContext.Provider value={{ currentStep, setCurrentStep, orderDetails, setOrderDetails }}>
      {children}
    </OrderContext.Provider>
  );
};
