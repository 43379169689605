import React, { useState, useContext } from 'react';
import { Modal, Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'; // Icône de magasin d'impression local
import CircleIcon from '@mui/icons-material/Circle';
import BindingIcon from '@mui/icons-material/Book';
import LooseDocumentIcon from '@mui/icons-material/InsertDriveFile';
import LaminationIcon from '@mui/icons-material/Layers';
import InfoIcon from '@mui/icons-material/Info';
import { OrderContext } from '../../context/OrderContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Ajouter cette ligne en haut avec les autres importations


const OptionsSelectionModal = ({ isOpen, onClose }) => {
  const { setCurrentStep, setOrderDetails, orderDetails } = useContext(OrderContext);
  const [selectedOptions, setSelectedOptions] = useState({
    impression: '',
    couleurImpression: '',
    rectoVerso: '',
    finition: '',
    couleurSpirale: '',
    couleurCouverture: '',
    plastification: '',
  });


  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const areAllOptionsSelected = () => {
    return selectedOptions.impression &&
           selectedOptions.couleurImpression &&
           selectedOptions.rectoVerso &&
           selectedOptions.finition &&
           (selectedOptions.finition !== 'reliure' || selectedOptions.couleurSpirale) && // Vérification conditionnelle pour la reliure
           (selectedOptions.finition !== 'plastification' || selectedOptions.plastification) && // Vérification conditionnelle pour la plastification
           selectedOptions.couleurCouverture;
  };
  

   // Fonction pour gérer le bouton "Retour"
   const handleBack = () => {
    setCurrentStep(1);
  };

  // Fonction pour gérer la fermeture de la modale
  const handleModalClose = () => {
    if (!areAllOptionsSelected()) {
      setShowConfirmCloseModal(true);
    } else {
      onClose();
    }
  };

  // Fonction pour gérer la confirmation de la fermeture
  const handleConfirmClose = () => {
    setShowConfirmCloseModal(false);
    onClose();
  };

  // Fonction pour annuler la fermeture
  const handleCancelClose = () => {
    setShowConfirmCloseModal(false);
  };

  // Styles pour les boutons d'options avec une largeur fixe
const optionButtonStyle = (selected, optionType) => ({
  borderRadius: '10px',
  margin: 1,
  border: '1px solid',
  borderColor: selected ? '#004AC1' : '#F7F7F7',
  backgroundColor: selected ? '#F7F7F7' : '#F7F7F7',
  color: selected ? 'black' : 'black',
  minWidth: '250px', // Largeur fixe pour tous les boutons
  '&:hover': {
    backgroundColor: selected ? '#F7F7F7' : 'grey.100',
    borderColor: selected ? '#004AC1' : 'grey.400',
  },
});

 

const blackCircleStyle = {
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: 'black',
  display: 'inline-block',
};

const ColorCircle = styled('div')({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  background: 'conic-gradient(from 0deg, red, orange, yellow, green, blue, indigo, violet)', // Dégradé conique
});

const continueButtonStyle = {
  mt: 2,
  backgroundColor: '#11775A', // Votre couleur personnalisée
  color: 'white',
  width:'250px',
  padding: '10px 20px', // Plus de padding
  borderRadius: '15px', // Plus de border radius
  '&:hover': {
    backgroundColor: 'lighten(#11775A, 0.1)', // Légèrement plus clair au survol
  },
};

// Styles pour le bouton "Retour" similaire au bouton "Continuer"
const backButtonStyle = {
  ...continueButtonStyle, // Copier le style de continueButtonStyle
  backgroundColor: '#666', // Changer la couleur de fond si nécessaire
  marginLeft: '10px', // Ajouter une marge à gauche
};
const coverColorButtonStyle = (color) => ({
  width: 36,
  height: 36,
  margin: '5px',
  borderRadius: '50%',
  border: '1px solid',
  borderColor: 'black',
  backgroundColor: color,
  '&:hover': {
    borderColor: 'black',
  },
});

const infoTooltipStyle = {
  color: 'lightgrey',
  marginRight: '8px',
  marginLeft :'10px',
  verticalAlign: 'middle',
};

const colorNameMap = {
  '#000080': 'Bleu Foncé',
  '#FFFFF0': 'Ivoire',
  '#FF0000': 'Rouge',
  '#FFFFFF': 'Blanc',
  '#000000': 'Noir',
  '#008000': 'Vert',
};
  // Fonction pour gérer le changement d'option
  const handleOptionChange = (optionType, value) => {
    setSelectedOptions({ ...selectedOptions, [optionType]: value });
  };

  // Fonction pour continuer
  const handleContinue = () => {
    setOrderDetails(prevDetails => ({ ...prevDetails, options: selectedOptions }));
    setCurrentStep(3);
    onClose();
  };
  

  // Styles pour le modal
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '70vh',
    overflowY: 'auto',
    width: '60%',
    textAlign: 'left' // Alignement du texte à gauche
  };


  return (
    <React.Fragment>

    <Modal open={isOpen} onClose={handleModalClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6">Choisissez vos options de commande</Typography>
  
        {/* Impression */}
        <Box display="flex" alignItems="center">
         
          <Typography variant="subtitle1">1 - Impression:</Typography>
        </Box>
        <Box>
          <Button
            sx={optionButtonStyle(selectedOptions.impression === 'jetEncre')}
            startIcon={<LocalPrintshopIcon />}
            onClick={() => handleOptionChange('impression', 'jetEncre')}
          >
            Jet d'encre
          </Button>
          <Button
            sx={optionButtonStyle(selectedOptions.impression === 'laser')}
            startIcon={<PrintIcon />}
            onClick={() => handleOptionChange('impression', 'laser')}
          >
            Laser
          </Button>
          <Tooltip title="Infos sur l'impression. Lorem ipsum dolor sit amet...">
            <InfoIcon sx={infoTooltipStyle} />
          </Tooltip>
        </Box>
  
        {/* Couleur d'impression */}
        {selectedOptions.impression && (
          <Box>
            <Box display="flex" alignItems="center">
              
              <Typography variant="subtitle1">2 - Couleur:</Typography>
            </Box>
            <Button
              sx={optionButtonStyle(selectedOptions.couleurImpression === 'noirs')}
              startIcon={<Box style={blackCircleStyle} />} // Cercle noir
              onClick={() => handleOptionChange('couleurImpression', 'noirs')}
            >
              Noir et Blanc
            </Button>
            <Button
              sx={optionButtonStyle(selectedOptions.couleurImpression === 'couleurs')}
              startIcon={<ColorCircle />} // Cercle de dégradé pour "Couleurs"
              onClick={() => handleOptionChange('couleurImpression', 'couleurs')}
            >
              Couleurs
            </Button>
            <Tooltip title="Choisissez une couleur. Lorem ipsum...">
                <InfoIcon sx={infoTooltipStyle} />
              </Tooltip>
          </Box>
        )}
  
        {/* Option Recto/Verso */}
        <Box display="flex" alignItems="center">
         
          <Typography variant="subtitle1" sx={{ mt: 2 }}>3 - Recto/Verso:</Typography>
        </Box>
        <Box>
          <Button
            sx={optionButtonStyle(selectedOptions.rectoVerso === 'rectoVerso')}
            onClick={() => handleOptionChange('rectoVerso', 'rectoVerso')}
          >
            Recto/Verso  
          </Button>
          <Button
            sx={optionButtonStyle(selectedOptions.rectoVerso === 'rectoSimple')}
            onClick={() => handleOptionChange('rectoVerso', 'rectoSimple')}
          >
            Recto Seul
          </Button>
          <Tooltip title="Options de Recto/Verso. Lorem ipsum...">
            <InfoIcon sx={infoTooltipStyle} />
          </Tooltip>
        </Box>
  
        {/* Finition */}
        <Box display="flex" alignItems="center">
         
          <Typography variant="subtitle1">4 - Finition:</Typography>
        </Box>
        <Box>
          <Button
            sx={optionButtonStyle(selectedOptions.finition === 'reliure')}
            startIcon={<BindingIcon />}
            onClick={() => handleOptionChange('finition', 'reliure')}
          >
            Reliure
          </Button>
          <Button
            sx={optionButtonStyle(selectedOptions.finition === 'documentsLibres')}
            startIcon={<LooseDocumentIcon />}
            onClick={() => handleOptionChange('finition', 'documentsLibres')}
          >
            Documents Libres
          </Button>
          <Button
            sx={optionButtonStyle(selectedOptions.finition === 'plastification')}
            startIcon={<LaminationIcon />}
            onClick={() => handleOptionChange('finition', 'plastification')}
          >
            Plastification
          </Button>
          <Tooltip title="Options de finition. Lorem ipsum...Options de finition. Lorem ipsum...Options de finition. Lorem ipsum...Options de finition. Lorem ipsum...">
            <InfoIcon sx={infoTooltipStyle} />
          </Tooltip>
        </Box>
  
        {/* Sous-options pour reliure */}
        {selectedOptions.finition === 'reliure' && (
          <Box>
            <Box display="flex" alignItems="center">
             
              <Typography variant="subtitle1">5 - Couleur Spirale:</Typography>
            </Box>
            <Button
              sx={optionButtonStyle(selectedOptions.couleurSpirale === 'blanc')}
              onClick={() => handleOptionChange('couleurSpirale', 'blanc')}
            >
              Blanc
            </Button>
            <Button
              sx={optionButtonStyle(selectedOptions.couleurSpirale === 'noir')}
              onClick={() => handleOptionChange('couleurSpirale', 'noir')}
            >
              Noir
            </Button>
            <Tooltip title="Choix de la couleur de la spirale. Lorem ipsum...">
                <InfoIcon sx={infoTooltipStyle} />
              </Tooltip>
          </Box>
        )}
  
        {/* Sous-options pour plastification */}
        {selectedOptions.finition === 'plastification' && (
          <Box>
            <Box display="flex" alignItems="center">
              
              <Typography variant="subtitle1">6 - Type de Plastification:</Typography>
            </Box>
            <Button
              sx={optionButtonStyle(selectedOptions.plastification === 'mat')}
              onClick={() => handleOptionChange('plastification', 'mat')}
            >
              Mat
            </Button>
            <Button
              sx={optionButtonStyle(selectedOptions.plastification === 'brillant')}
              onClick={() => handleOptionChange('plastification', 'brillant')}
            >
              Brillant
            </Button>
            <Tooltip title="Type de plastification. Lorem ipsum...">
                <InfoIcon sx={infoTooltipStyle} />
              </Tooltip>
          </Box>
        )}
   {/* Section des options de couleur de couverture */}
   {selectedOptions.finition === 'reliure' && (
     <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" sx={{ mt: 2 }}>7 - Couleur Couverture:</Typography>
            </Box>
            <Box>
              {Object.entries(colorNameMap).map(([color, name]) => (
                <Tooltip key={color} title={name}>
                  <IconButton
                    sx={coverColorButtonStyle(color)}
                    onClick={() => handleOptionChange('couleurCouverture', color)}
                    style={{ border: selectedOptions.couleurCouverture === color ? '2px solid #004AC1' : '' }}
                  />
                </Tooltip>
              ))}
            </Box>
            </Box>
          )}

          {/* Boutons "Continuer" et "Retour" */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
             <Button
              variant="contained"
              sx={backButtonStyle}
              onClick={handleBack}
              startIcon={<ArrowBackIcon />} // Icone pour le retour, si nécessaire
            >
              Retour
            </Button>
            <Button
              variant="contained"
              sx={continueButtonStyle}
              onClick={handleContinue}
              startIcon={<ArrowForwardIcon />}
              disabled={!areAllOptionsSelected()}
            >
              Continuer
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Modale de confirmation de fermeture */}
      <Dialog
        open={showConfirmCloseModal}
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Quitter la commande ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir quitter ? Vos sélections seront perdues.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Oui, quitter
          </Button>
          <Button onClick={handleCancelClose} color="primary" autoFocus>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

  );
  
};

export default OptionsSelectionModal;
