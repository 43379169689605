import React from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from 'qrcode.react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const ContactModal = ({ isOpen, onClose }) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500, // Largeur de la modale
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px', // Coins arrondis
    maxHeight: '90vh', // Hauteur maximale avant de défiler
    overflowY: 'auto', // Défilement automatique sur l'axe Y
  };

  const qrContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 20px', // Ajoutez une marge autour des QR codes
  };

  const qrButtonStyle = {
    marginTop: '10px',
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    borderRadius: '5px',
    cursor: 'pointer',
    background: '#FD0A7A',
    border: 'none',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px', // Assurez-vous que les boutons ont la même largeur
  };

  const linkStyle = {
    textDecoration: 'none', // Ajoutez cette ligne pour enlever le soulignage
    ...qrButtonStyle // Réutilisez le style existant des boutons
  };

 return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={qrContainerStyle}>
            <QRCode value="tel:+33955739901" />
            <p>+33 9 55 73 99 01</p>
            <a href="tel:+33955739901" style={linkStyle}>
              <PhoneIcon style={{ marginRight: '5px' }} /> Appeler
            </a>
          </div>
          <div style={qrContainerStyle}>
            <QRCode value="mailto:contact@printmydoc.fr" />
            <p>contact@printmydoc.fr</p>
            <a href="mailto:contact@printmydoc.fr" style={linkStyle}>
              <EmailIcon style={{ marginRight: '5px' }} /> Email
            </a>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ContactModal;