import React from 'react';

const styles = {
    footer: {
      position: 'absolute',  // Modifié pour coller le footer en bas
      bottom: 0,             // Ajouté pour garantir que le footer reste en bas
      color: 'white',
      textAlign: 'center',
      width: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
    }
  };

const FooterComponent = () => {
  return (
    <footer style={styles.footer}>
      <p>© {new Date().getFullYear()} PrintmyDoc. Tous droits réservés.</p>
    </footer>
  );
};

export default FooterComponent;
