import React, { useState, useEffect, useContext } from 'react';
import HeaderComponent from './HeaderComponent';
import MainContent from './MainContent';
import FooterComponent from './FooterComponent';
import ContactModal from './ContactModal';
import OrderFormModal from './Order/OrderFormModal';
import OptionsSelectionModal from './Order/OptionsSelectionModal'; // Assurez-vous d'importer ce composant
import SummaryPageModal from './Order/SummaryPageModal'; // Assurez-vous d'importer ce composant
import EmailConfirmationModal from './Order/EmailConfirmationModal'; // Assurez-vous d'importer ce composant
import FinalConfirmationModal from './Order/FinalConfirmationModal'; // Assurez-vous d'importer ce composant
import LoadingSkeleton from './LoadingSkeleton ';
import { OrderContext } from '../context/OrderContext'
const pageStyle = {
    minHeight: '100vh',   // Garantit que le contenu remplit la hauteur de la fenêtre
    position: 'relative', // Nécessaire pour le footer en position absolue
    overflowX: 'hidden',  // Cache le défilement horizontal
  };

const ComingSoonPage = () => {
  const { currentStep } = useContext(OrderContext);
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(currentStep === 1);

  useEffect(() => {
    setIsOrderModalOpen(currentStep === 1);
  }, [currentStep]);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const openFormModal = () => setIsOrderModalOpen(true);
  const closeFormModal = () => setIsOrderModalOpen(false);

  return (
      <div style={pageStyle}>
        <HeaderComponent />
        {isLoading ? <LoadingSkeleton /> : <MainContent openModal={openModal} openFormModal={openFormModal} />}
        <ContactModal isOpen={modalIsOpen} onClose={closeModal} />
        <OrderFormModal isOpen={isOrderModalOpen} onClose={closeFormModal} />
        <OptionsSelectionModal isOpen={currentStep === 2} onClose={closeFormModal} />
        <SummaryPageModal isOpen={currentStep === 3} onClose={closeFormModal} />
        <EmailConfirmationModal isOpen={currentStep === 4} onClose={closeFormModal} />
        <FinalConfirmationModal isOpen={currentStep === 5} onClose={closeFormModal} />
        <FooterComponent />
      </div>
  );
};

export default ComingSoonPage;

