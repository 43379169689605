import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import InstagramIcon from '@mui/icons-material/Instagram';
import logo from '../assets/logo page web.png';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0.5rem 1rem',
    margin: 0,
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    borderTop: '5px solid #FD0A7A',
  },
  logo: {
    height: '40px',
    width: 'auto',
    margin: '0 0.5rem',
  },
  iconColor: {
    color: '#505050', // Gris foncé
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'start',
    paddingRight: '2rem',
  }
};

const HeaderComponent = () => {
  return (
    <header style={styles.header}>
      <div style={styles.logo}>
        {/* Ajout de mots-clés SEO dans l'attribut alt */}
        <img src={logo} alt="PrintmyDoc, services d'impression et de reliure à Amiens" style={styles.logo} />
      </div>
      <div style={styles.socialIcons}>
        {/* Ajout de l'attribut title pour chaque lien */}
        <a href="https://www.facebook.com/people/Printmydoc/61553620238680/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }} title="PrintmyDoc sur Facebook">
          <FacebookIcon style={styles.iconColor} />
        </a>
        <a href="https://wa.me/+33610354259" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }} title="Contactez PrintmyDoc sur WhatsApp">
          <WhatsAppIcon style={styles.iconColor} />
        </a>
        <a href="https://www.instagram.com/_printmydoc/?fbclid=IwAR3nP-gC2Wcl3BrXLHHJH_8G88qzVCZ4kI2x8fjrlV3Ws7pXlBkcWk9QrqE" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }} title="PrintmyDoc sur Instagram">
          <InstagramIcon style={styles.iconColor} />
        </a>
      </div>
    </header>
  );
};

 

export default HeaderComponent;
