import React, { useContext } from 'react';
import { Modal, Box, Typography, Button, IconButton, Alert, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { OrderContext } from '../../context/OrderContext';

const SummaryPageModal = ({ isOpen, onClose }) => {
  const { orderDetails, setCurrentStep, currentStep } = useContext(OrderContext);

  const handleEditUserDetails = () => {
    setCurrentStep(1);
  };

  const handleEditFileDetails = () => {
    setCurrentStep(1);
  };

  const handleEditOptions = () => {
    setCurrentStep(2);
  };

  const calculatePriceDetails = () => {
    let details = [];
    const numPages = orderDetails.uploadedFile ? orderDetails.uploadedFile.reduce((acc, file) => acc + file.numPages, 0) : 0;

    // Texte descriptif pour le type d'impression et la couleur
    const impressionDescription = (type, couleur) => {
      let description = '';
      if (type === 'jetEncre') {
        description += 'jet d\'encre ';
        description += couleur === 'noirBlanc' ? 'noir et blanc' : 'couleur';
      } else if (type === 'laser') {
        description += 'laser ';
        description += couleur === 'noirBlanc' ? 'noir et blanc' : 'couleur';
      }
      return description;
    };

    // Détermination du prix par page en fonction du type d'impression et de la couleur
    let pricePerPage = 0;
    let typeImpression = orderDetails.options?.impression;
    let couleurImpression = orderDetails.options?.couleurImpression;

    if (typeImpression === 'jetEncre') {
      pricePerPage = couleurImpression === 'noirBlanc' ? 0.15 : 0.25;
    } else if (typeImpression === 'laser') {
      pricePerPage = couleurImpression === 'noirBlanc' ? 0.10 : 0.20;
    }

    details.push({
      label: `Impression (${numPages} pages ${impressionDescription(typeImpression, couleurImpression)} x ${pricePerPage.toFixed(2)} €)`,
      cost: numPages * pricePerPage
    });

    // Ajout des frais pour reliure ou plastification
    if (orderDetails.options?.finition === 'reliure') {
      details.push({ label: "Reliure", cost: 3.5 });
    } else if (orderDetails.options?.finition === 'plastification') {
      details.push({ label: `Plastification (${numPages} pages)`, cost: 2.5 * numPages });
    }

    // Frais de livraison
    details.push({ label: "Livraison", cost: 3.90 });

    // Frais de service avec réduction
    let fraisService = 1.35 - (0.10 * Math.floor(numPages / 10));
    details.push({ label: "Frais de service", cost: fraisService });

    return details;
  };

  const totalPrice = () => {
    return calculatePriceDetails().reduce((total, item) => total + item.cost, 0).toFixed(2);
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    maxHeight: '90vh',
    overflowY: 'auto',
    width: '50%',
  };

  const blockStyle = {
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    border: '1px solid #ddd',
    padding: '10px',
    marginTop: '10px',
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6">Résumé de la Commande</Typography>

        {/* Bloc des infos utilisateur */}
        <Box sx={blockStyle} mt={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">Informations Utilisateur</Typography>
            <IconButton onClick={handleEditUserDetails}><EditIcon /></IconButton>
          </Box>
          <Typography>{orderDetails.nom} {orderDetails.prenom}</Typography>
          <Typography>{orderDetails.adresse}</Typography>
          <Typography>{orderDetails.email}</Typography>
          <Typography>{orderDetails.telephone}</Typography>
        </Box>

        {/* Bloc des détails du fichier */}
        <Box sx={blockStyle} mt={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">Détails du Fichier</Typography>
            <IconButton onClick={handleEditFileDetails}><EditIcon /></IconButton>
          </Box>
          {orderDetails.uploadedFile && orderDetails.uploadedFile.map((file, index) => (
            <Typography key={index}>{file.name} - {file.numPages} pages</Typography>
          ))}
        </Box>

        {/* Bloc des options de livraison */}
        <Box sx={blockStyle} mt={2}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">Options de Livraison</Typography>
            <IconButton onClick={handleEditOptions}><EditIcon /></IconButton>
          </Box>
          {orderDetails.options && Object.entries(orderDetails.options).map(([key, value]) => (
            <Typography key={key}>{key}: {value}</Typography>
          ))}
        </Box>

        {/* Bannière de livraison */}
        <Alert severity="info" sx={{ mt: 2 }}>Livraison le jour même sur Amiens et ses alentours</Alert>

       {/* Bloc de calcul du prix */}
       <Box sx={blockStyle} mt={2}>
          <Typography variant="subtitle1">Détails des Coûts</Typography>
          <Grid container>
            {calculatePriceDetails().map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={8}>
                  <Typography>{item.label}</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  <Typography>{`${item.cost.toFixed(2)} €`}</Typography>
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={8}>
              <Typography><strong>Total</strong></Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Typography><strong>{totalPrice()} €</strong></Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Bannière de livraison */}
        <Alert severity="info" sx={{ mt: 2 }}>* Paiement à la livraison</Alert>

        {/* Note sur le paiement à la livraison */}
        <Typography variant="caption" sx={{ mt: 2 }}></Typography>

        {/* Boutons "Continuer" et "Retour" */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" onClick={() => setCurrentStep(currentStep - 1)}>Retour</Button>
          <Button variant="contained" onClick={() => setCurrentStep(currentStep + 1)}>Continuer</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SummaryPageModal;